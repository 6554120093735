@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
  background-color: #f5f5f5;
}
.react-calendar__navigation {
  display: flex;
  margin-bottom: 10px;
}
.react-calendar__tile--active {
  background-color: #5a13f0;
  border-radius: 14px;
  color: white;
}
